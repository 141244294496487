.body {
  overflow: hidden;
  display: flex;
  flex-flow: column;
  gap: 0.7rem;
  padding: 1.1rem;
}

.header {
  display: flex;
  align-items: center;
}

.date {
  font-size: 1.2rem;
  color: var(--color--secondary);
  margin-right: 30px;
}

.close {
  margin-left: auto;
  width: 2rem;
  height: 2rem;
  position: initial;
}

.pciWrapper {
  display: flex;
  font-size: 1.4rem;
  align-items: center;
  justify-content: center;
}

.prev,
.next {
  position: absolute;
  height: 2.2rem;
  top: calc(50% - 1.1rem);
  padding: 0.2rem 0.4rem;
  background-color: rgba(0, 0, 0, 0.4);
  user-select: none;
  transition: 0.4s all ease;
  z-index: 1;
  &:hover {
    background-color: rgba(0, 0, 0, 1);
    transition: 0.4s all ease;
  }
}
.prev {
  left: 0;
  border-radius: 0 25% 25% 0;
}
.next {
  right: 0;
  border-radius: 25% 0 0 25%;
}

.roadname {
  font-size: 1.2rem;
  color: var(--color--secondary);
  margin-right: auto;
  text-transform: capitalize;
}