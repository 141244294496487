.mapTopBar{
    position: absolute;
    height: var(--topBar-height);
    left: 0;
    top: var(---view-padding-m);
    right: var(---view-padding-m);
    left: var(---view-padding-m);
    z-index: 1;
    display: flex;
    flex-direction: row;
    background: var(--bg-color);
    border: 1px solid var(--border-color);
    border-radius: var(---view-padding);
  }