.container {
    border-right: 1px solid var(--separator-color);
    padding-left: 28px;
    padding-right: 28px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.laneBox { 
    display: flex;
    flex-direction: column;
}

.centerlineBox {
    display: flex;
    flex-direction: column;
}

.centerlineText {
    color: var(--border-color-lighter);
    font-size: 14px;
    line-height: 20px;
}

.divider { 
    width: 1px;
    height: 75%; 
    background: var(--border-color); 
    margin: 0 28px; 
}
  
.laneText {
    color: var(--border-color-lighter);
    font-size: 14px;
    line-height: 20px;
}
  
.value {
    font-size: 20px;
    color: #fff;
    font-weight: 500;
}