.popoverContent { 
    background: var(--bg-color); 
    right: var(---view-padding-m);
  }

  .pciRange {
    display: flex;
    flex-direction: row;
    padding-bottom: 16px;
    align-items: center;
  }

  .pciRangeNumber {
    background-color: #2C2C2C;
    border: 1px solid #383839;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 52px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
  }

  .filterName{ 
    padding-bottom: 10px; 
    font-size: 16px; 
    font-weight: 500; 
  }

  .checkboxLabel{
    margin-left: 4px;
    font-size: 14px;
  }