:root {
  --bg-color: #232323;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  --color: #dadce0;
  --color--secondary: #9aa0a6;
  --color--help: #ddd;
  --color--blue: #3888FF;
  --border-color: #383839;
  --border-color--light: rgba(5, 4, 4, 0.4);
  --border-color-lighter: #979BA6;
  --separator-color: #464647;
  --link-color--active: var(--chakra-colors-blue-500);
  --sidenav-width: 3.5rem;
  --mapHeader-height: 7rem;
  --topBar-height: 4.25rem;
  --input-border-radius: var(--chakra-radii-md);
  ---view-padding: 1rem;
  ---view-padding-m: 1.5rem;
  --box-bg: linear-gradient(0deg, rgba(209, 225, 255, 0.08), rgba(209, 225, 255, 0.08)), rgb(31, 31, 31);
  /* Updated:  */
  --Color-Lighter-Grey : #979BA6;
  /* /rem */
  --rem-7px : 0.438rem;
  --rem-8px : 0.5rem;
  --rem-10px : 0.625rem;
  --rem-12px : 0.75rem;
  --rem-14px : 0.875rem;
  --rem-16px : 1rem;
  --rem-18px : 1.125rem;
  --rem-20px : 1.25rem;
  --rem-22px : 1.375rem;
  --rem-24px : 1.5rem;
  --rem-28px : 1.75rem;
  --rem-32px : 2rem;
  --rem-44px : 2.75rem;
  --rem-68px : 4.25rem;
  --rem-100px : 6.25rem;
  --rem-112px : 7rem;
  --rem-172px : 10.75rem;
  --rem-500px : 31.25rem;
  --rem-584px : 36.5rem;
}

html,
body,
#container {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100vh;
}

body {
  overflow: hidden;
  font-family: 'Satoshi', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color);
  background: var(--bg-color);
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

h1 {
  font-weight: 700;
  font-size: 1.5rem;
}
h2 {
  font-weight: 500;
  font-size: 1.2rem;
}

a {
  color: var(--color);
  background: transparent;
  &.active,
  &:focus,
  &:hover,
  &:active {
    color: var(--link-color--active);
    background: transparent;
    & > button {
      color: var(--link-color--active);
    }
  }
  &:visited {
    background: transparent;
  }
}

.material-symbols-outlined {
  font-size: 1.6rem;
}

/**
* The following styles are for the Map.tsx but have to live here because the markers are
* created programmatically via JS so we can apply this styles via the HTML attribute 'class'.
*/
.map {
  position: relative;
  user-select: none;
  &,
  &::focus,
  &::focus-visible,
  &:focus-within {
    outline: none !important;
    border-color: red !important;
    box-sizing: border-box !important;
  }
  .mapMarker{
    width: 12px;
    opacity: 0.8;
    transition: transform 0.15s ease-in-out;
    cursor: pointer;
    &:hover {
      opacity: 1;
      transition: all 0.15s ease-in-out;
      transform: scale(1.025);
    }
  }
  .signMarker {
    width: 14px;
    opacity: 0.8;
    transition: transform 0.15s ease-in-out;
    cursor: pointer;
    &:hover {
      opacity: 1;
      transition: all 0.15s ease-in-out;
      transform: scale(1.025);
    }
  }
  .signMarker2 {
    width: 24px;
    opacity: 1;
    transition: transform 0.15s ease-in-out;
    cursor: pointer;
    &:hover {
      opacity: 1;
      transition: all 0.15s ease-in-out;
      transform: scale(1.025);
    }
    animation: pulse 1.5s infinite;
  }
  .inletMarker {
    width: 16px;
    opacity: 0.8;
    transition: transform 0.15s ease-in-out;
    cursor: pointer;
    &:hover {
      opacity: 1;
      transition: all 0.15s ease-in-out;
      transform: scale(1.025);
    }
  }
  .inletMarker2 {
    width: 20px;
    opacity: 1;
    transition: transform 0.15s ease-in-out;
    cursor: pointer;
    &:hover {
      opacity: 1;
      transition: all 0.15s ease-in-out;
      transform: scale(1.025);
    }
    animation: pulse 1.5s infinite;
  }

  .mapToolbar {
    display: flex;
    gap: 0.5rem;
    padding: var(---view-padding-m);
    position: absolute;
    left: 0;
    top: 86px;
    z-index: 1;
    color: var(--color);
    .mapSearchInputIcon {
      height: 100%;
      width: auto;
      padding: 0.5rem;
      .material-symbols-outlined {
        color: var(--color--secondary);
        font-size: 1.2rem;
      }
    }
    .mapSearchInput {
      background: var(--bg-color);
      color: var(--border-color-lighter);
    }
    .mapMode {
      display: flex;
      align-items: center;
    }
    /* .mapClearInputIcon {
      height: 100%;
      &:hover {
        cursor: pointer;
      }
      .material-symbols-outlined {
        color: var(--color--secondary);
        font-size: 1.2rem;
      }
    } */
    .openMapSearch,
    .fitBounds,
    .deselectSection {
      background: var(--bg-color);
      height: 40px;
      width: 40px;
      color: var(--color--secondary);
      &:hover {
        background: var(--chakra-colors-gray-600);
        color: var(--color);
      }
    }
  }

  .rightToolbar {
    right: 0;
    left: inherit;
  }
}

.zoomist-container {
  width: 100%;
  height: 100%;
  /* max-width: 600px; */
}
.zoomist-image {
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    object-position: center;
  }
}

.chakra-modal__content-container {
  justify-content: flex-end !important;
  padding-right: var(---view-padding-m);
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.radio_button_unchecked {
  font-variation-settings:
  'FILL' 0,
  'wght' 700,
  'GRAD' 200,
  'opsz' 24
}