.hamburgerIconBox {
    width: var(--topBar-height);
    height: 100% !important;
    cursor: pointer;
    background: none !important;
}

.menuicon {
    padding-right: 10px;
    border-left: 1px solid var(--separator-color);
}